div.app-details{
    padding: 32px 52px 0px 44px;
}
.app-details .ant-menu-item{
    height: 58px;
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--white);
    margin-top: 0px;
    margin-bottom: 0px !important;
    font-family: SFProDisplay-Semibold;
    font-size: 14px;

    color: var(--slate-grey);
    padding-top: 8px;
}
.app-details .ant-menu-item:hover{
    height: 58px;
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--pale-grey);
    margin-top: 0px;
    margin-bottom: 0px !important;
    font-family: SFProDisplay-Semibold;
    font-size: 14px;

    color: #238ee5;

    padding-top: 8px;
}
.app-details .ant-menu-item-selected::after{
    border-right: none;
}
.app-details .ant-menu-item:not(:first-child){
    border-top:  none;
}
.app-details .ant-menu-item:first-child{
    border-radius:  4px 4px 0 0;
}
.app-details .ant-menu-item:last-child{
    border-radius: 0 0 4px 4px;
}
.app-details .ant-menu-item-selected{
    color: var(--dark-sky-blue);
    background: #e8f4ff !important;
}

.product-group-body{
    border-top: none;
    border-radius:  0 0 4px 4px;
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--pale-grey-three);
    padding: 30px 30px 30px 36px;
}
.product-group-head{
    position: relative;
    height: 42px;
    border-radius:  4px 4px 0 0;
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--white);
    padding: 0 0 0 12px;
    border-bottom: none;
}
.offer{
    position: relative;
    height: 40px;
    width: 100%;
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--pale-grey-three);
    padding: 0 0 0 18px;
    border-bottom: none;
    border-top: none;
}
.product{
    margin-left: 24px;
    margin-right: 28px;
    position: relative;
    height: 40px;
    width: 100%;
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--white);
    padding: 0 0 0 18px;
    border-bottom: none;
    border-top: none;
}
.product-item{
    height: 80px;
    width: 100%;
    border:  solid 1px var(--pale-grey-two);
    border-radius: 4px;
}
.offer-head{
    position: relative;
    height: 40px;
    width: 100%;
    margin-left: 24px;
    margin-right: 28px;
    border-radius:  4px 4px 0 0;
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--white);
    padding: 0 0 0 12px;
    border-bottom: none;
}
.body-head{
    position: relative;
    height: 62px;
    border-top: none;
    border-bottom: none;
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--pale-grey-three);
    padding: 20px 28px 10px 18px;
    border-bottom: none;
}
.line-dark-seafoam-green{
    margin-left: 6px;
    z-index: 20;
    width: 24px;
    position: absolute;
    height: calc(100% + 1px);
    border: 1px solid var(--dark-seafoam-green);
    border-top: none;
    border-right: none;
    border-bottom: none;
}
.line-dodger-blue{
    margin-left: 6px;
    z-index: 20;
    width: 24px;
    position: absolute;
    height: calc(100% + 1px);
    border: 1px solid var(--dodger-blue);
    border-top: none;
    border-right: none;
    border-bottom: none;
}
.dot-line-dark-seafoam-green{
    margin-left: 6px;
    z-index: 20;
    top: 50%;
    width: 24px;
    position: absolute;
    height: calc(50% + 1px);
    border: 1px solid var(--dark-seafoam-green);
    border-top: none;
    border-right: none;
    border-bottom: none;

}
.dot-line-dodger-blue{
    margin-left: 6px;
    z-index: 20;
    top: 50%;
    width: 24px;
    position: absolute;
    height: calc(50% + 1px);
    border: 1px solid var(--dodger-blue);
    border-top: none;
    border-right: none;
    border-bottom: none;

}
.line-right-dark-seafoam-green{
    margin-left: 6px;
    z-index: 20;
    top: 0;
    width: 24px;
    position: absolute;
    height: 20px;
    border: 1px solid var(--dark-seafoam-green);
    border-top: none;
    border-right: none
}
.line-right-dodger-blue{
    margin-left: 6px;
    z-index: 20;
    top: 0;
    width: 24px;
    position: absolute;
    height: 20px;
    border: 1px solid var(--dodger-blue);
    border-top: none;
    border-right: none
}
.dot-dark-seafoam-green {
    margin-top: 11px;
    z-index: 15;
    height: 12px;
    width: 12px;
    background-color: var(--dark-seafoam-green);
    border-radius: 50%;
    display: inline-block;
}
.dot-dodger-blue {
    margin-top: 10px;
    z-index: 15;
    height: 12px;
    width: 12px;
    background-color: var(--dodger-blue);
    border-radius: 50%;
    display: inline-block;
}
.C1-H{
    position: relative;
    width: 100%;
    height: 41px;
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--white);
    padding: 4px 0px 4px 12px;
    border-radius: 5px 5px 0px 0px;
    border-bottom: none;
}
.C1-B{
    position: relative;
    width: 100%;
    /* height: 62px; */
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--white);
    padding: 0px 28px 0px 12px;
    border-bottom: none;
    border-top: none;
}
.C1-F{
    position: relative;
    width: 100%;
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--white);
    padding: 0px 10px 12px 12px;
    border-radius: 0px 0px 5px 5px;
    border-top: none;
    margin-bottom: 20px;
}
.C1-HC{
    margin-left: 12px;
    width: calc(100% - 56px);
}
.C1-BC{
    margin: 20px 0 19px 30px;
    margin-bottom: 10px;
    width: 100%;
}
.C2-HC{
    width: calc(100% - 56px);
    margin-left: 12px;
}
.C2-BC{
    width: 100%;
    margin-left: 30px;
    margin-bottom: 10px;
    margin-top: 20px;
}
.C2-H{
    position: relative;
    width: 100%;
    height: 40px;
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--white);
    padding: 4px 0px 4px 12px;
    margin-left: 30px;
    border-radius: 5px;
    border-radius: 5px 5px 0px 0px;
    border-bottom: none;
}
.C2-B{
    position: relative;
    width: 100%;
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--white);
    padding: 0px 28px 0px 12px;
    margin-left: 30px;
    border-bottom: none;
    border-top: none;
}
.C2-F{
    position: relative;
    width: 100%;
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--white);
    padding: 10px 10px 8px 0px;
    margin-left: 30px;
    border-radius: 0px 0px 5px 5px;
    border-top: none;
    margin-bottom: 16px;
}
.C3{
    position: relative;

    width: 100%;
    padding: 0px 0px 0px 0px;
    margin-left: 30px;
    border-radius: 5px;
}

.divider {
    height: 1px;
    width: 100%;
    background-color: var(--pale-grey-two);
}
.divider-C2 {
    height: 1px;
    width: 100%;
    margin-left: 30px;
    background-color: var(--pale-grey-two);
}

.webhook{
    max-width: 675px;
    margin-top: 10px;
}

.webhook-form{
    border:  solid 1px var(--pale-grey-two);
    background-color: var(--white);
    padding: 22px 24px 12px 24px;
    margin-bottom: 24px;
}
.webhook-form .ant-form-item-label label::before {
    font-size: 13px;
    font-family: SFProDisplay-Medium;
}

.webhook-form .ant-skeleton-content .ant-skeleton-title{
    margin-top: 6px;
}
.webhook-form .ant-skeleton-content .ant-skeleton-paragraph{
    margin-top: 26px;
}
.webhook-form .ant-skeleton-content .ant-skeleton-paragraph > li{
    width: 100% !important;
}



.settings{
    max-width: 530px;;
}
.product-header {
    height: 40px;
    padding: 12px 18px;
}

/* products */

.product-list .ant-table-header-column .ant-table-column-title {
    color: var(--pale-red);
    font-family: SFProDisplay-Semibold;
    font-size: 14px;

}

.keys-table .ant-table-header-column .ant-table-column-title {
    font-family: SFProDisplay-Semibold;
    font-size: 13px;
    color: var(--pale-red);
}
.keys-table span.ant-typography {
    font-size: 13px;
    font-family: SFProDisplay-Medium;
    font-size: 12px;

}
.keys-table .ant-table-body{
    /*border: 1px solid var(--reddish);*/
}

.app-details .ant-tabs .ant-tabs-left-content{
    padding-left: 0px;
}
.empty-group-container{
    border-radius: 6px;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05);
    background-color: #e8f4ff;
    padding: 18px 36px 30px 12px;
}

.empty-group-container-content {
    width: calc(100% - 40px);
}



.select-group-spinner{
    padding-top: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border: solid 1px var(--cloudy-blue);
    width: 206px;
    height: 42px;
    border-radius: 5px;
}

.product-list .ant-select-selection--single {
    font-family: SFProDisplay-Semibold;
    font-size: 14px;
    padding: 6px;
    height: revert;
    min-width: 206px;
}

.product-list .ant-select-selection--single:hover {
    border: solid 1px var(--dark-sky-blue);
}

.product-list .ant-select-selection--single:active {
    border: solid 1px var(--dark-sky-blue);
}

.product-list .ant-select-selection--single:focus {
    border: solid 1px var(--dark-sky-blue);
}

.image-price-history{
    margin-left: 4px;
    margin-bottom: 2px;
    filter: opacity(0.5);
}

.product-create-info{
    padding: 14px;
    border-radius: 6px;
    background-color: #eff8ff;
    margin-bottom: 20px;
}

.product-create-warning{
    padding: 14px;
    border-radius: 6px;
    background-color: #fffbe6;
    margin-bottom: 20px;
}

.product-create-info-content{
    width: calc(100% - 46px);
    margin-left: 16px;
}


.price-history .ant-table-thead > tr > th {
    font-family: SFProDisplay-Semibold;
    font-size: 13px;

    color: var(--dark);
}

.app-details-left-menu{
    position: absolute;
}

.more-horizontal.ant-dropdown-trigger{
    padding-bottom: 6px;
}

.product-row{
    padding-top: 2px;
    margin-top: -4px;
    margin-bottom: -4px;
}
.product-row > div:last-child{
    padding-top: 4px;
}

.settings-form .ant-input{
    height: 42px;
}

.ant-upload-drag-icon{
    visibility: hidden;
    height: 0;
    margin-bottom: 0 !important;
}

.webhook_url{
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    height: 42px;
    border: 1px solid #d4dde5;
    border-radius: 4px;
    padding-left: 12px;
}

.activate-product:hover{
    cursor: pointer;
}

.group-spinner{
    position: absolute;
    z-index: 10;
    width: calc(100% - 130px);
    text-align: center;
    top: 400px;
}

.product-spinner-group{
    visibility: hidden!important;
}



