.alert-list{
    padding: 32px 44px 0px 44px;
}

.alert-create-form{
    background-color: var(--white);
    padding: 30px 25px 30px 25px;
    max-width: 600px;
    border-radius: 5px;
}
.alert-create-form .ant-input{
    height: 42px;
    font-family: SFProDisplay-Regular;
    font-size: 15px;
}
.alert-create-form .ant-select-selection-selected-value{
    font-family: SFProDisplay-Semibold;
    font-size: 15px;
}
.alert-create-form .ant-select-lg .ant-select-selection--single{
    height: 42px;
}
.alert-create-form .ant-select-selection-selected-value{
    margin-top: 2px;
}

.alert-switch{
    font-family: SFProDisplay-Medium;
    font-size: 14px;
    color: var(--water-blue);
}
.country_code .ant-select-dropdown-menu {
    max-height: 150px;
}

.country-code-number .ant-input{
   padding-left: 48px !important;
}

.alert-create-form .ant-select-selection__rendered{
    line-height: 41px;
}


